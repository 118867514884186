import { useLocation } from 'react-router-dom';

const useEmailFromLocationState = () => {
  const loc = useLocation();

  let email = '';
  if (loc.state?.email) {
    email = loc.state.email;

    // clean the state so if the user refreshes the page or accesses the link again it's not there
    window.history.replaceState({}, document.title);
  }

  return email;
};

export default useEmailFromLocationState;