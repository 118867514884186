import AcnLogo from "../../UI/icons/AcnLogo";
import AxaLogo from "../../UI/icons/AxaLogo";
import CrelanLogo from "../../UI/icons/CrelanLogo";
import ExternalLoginButton from "./ExternalLoginButton";

const providers = [
    {
        name: "ACN",
        logo: AcnLogo,
        classes: "hover:bg-purple-300 focus:bg-purple-300",
    },
    {
        name: "AXA",
        logo: AxaLogo,
        classes: "hover:border-[#ff1721] focus:border-[#ff1721]",
        bgClass: "bg-[#00008f]"
    },
    {
        name: "CRELAN",
        logo: CrelanLogo,
        classes: "hover:bg-[#c4d600] focus:bg-green-100 items-center",
    }
];

const ExternalLoginButtons = () => {
    return (
        <>
            {providers.map((provider, index) => {
                return (
                    <ExternalLoginButton
                        key={index}
                        providerName={provider.name}
                        Logo={provider.logo}
                        classes={provider.classes}
                        bgClass={provider.bgClass}
                    />
                );
            })}
        </>
    );
};

export default ExternalLoginButtons;
