import { FunctionComponent } from "react";
import Spinner from "../../UI/icons/spinner";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

const SubmitButton: FunctionComponent<{
  text: string;
  isProcessing?: boolean;
  isDisabled?: boolean;
  isCompleted?: boolean;
}> = ({ text, isProcessing, isDisabled, isCompleted}) => {
  const btnClass =
    (isCompleted
      ? "bg-green-600 "
      : isDisabled
      ? "bg-gray-300 "
            : "h-12 xs:h-10 bg-core-purple-2 hover:bg-core-purple-1 focus:outline-none focus:ring-2 focus:ring-focus") +
      "flex w-full justify-center rounded px-3 py-1.5 text-base xs:text-sm font-semibold leading-6 text-white shadow-sm" 

  return (
    <div>
      <button
        type="submit"
        className={btnClass}
        disabled={isDisabled}
      >
        {isProcessing ? <Spinner></Spinner> : isCompleted?  <CheckCircleIcon className="h-6 w-6"/>: text}
      </button>
    </div>
  );
};

export default SubmitButton;

