import InputProps from "../../models/InputProps";
import { forwardRef } from "react";

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.type === "tel") {
      const cleanValue = event.target.value.replace(/\D/g, '');
      event.target.value = cleanValue;
    }

    if (props.onChange) {
      props.onChange(event);
    }
  }

  return (
    <div className={props.className}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={`block text-sm font-medium leading-6 ${props.labelClassName}`}
        >
          {props.label}
        </label>
      )}

      <div className="mt-1">
        <input
          type={props.type}
          id={props.id}
          required={props.required}
          defaultValue={props.value}
          onChange={handleInputChange}
          {...(props.name ? { name: props.name } : {})}
          {...(props.placeholder ? { placeholder: props.placeholder } : {})}
          {...(props.disabled ? { disabled: props.disabled } : {})}
          {...(props.autoComplete ? { autoComplete: props.autoComplete } : {})}
          {...(props.autoFocus ? { autoFocus: props.autoFocus } : {})}
          {...(props.readOnly ? { readOnly: props.readOnly } : {})}
          className={`${props.disabled? "bg-gray-300" : ""} h-12 xs:h-auto block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 focus:ring-core-purple-1`}
          ref={ref}
        />
      </div>
    </div>
  );
});

export default Input;
