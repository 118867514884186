import logo from "../../media/goliat-logo.png";

function Brand() {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md mb-10">
      <img className="mx-auto h-32 w-auto min-w-[200px]" src={logo} alt="GoLiAT" />
    </div>
  );
}

export default Brand;
