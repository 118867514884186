import { useParams, useNavigate } from "react-router";
import Spinner from "../UI/icons/spinner";
import { useState, useEffect } from "react";
import {
  ShieldCheckIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/24/outline";
import ActionText from "../components/Form/ActionText";

const PasswordForgotTokenPage = () => {
  const { token } = useParams();
  const [isPending, setIsPending] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const navigation = useNavigate();

  useEffect(() => {
    async function validateToken() {
      const response = await fetch("/reset-password/" + token);
      if (response.status === 200) {
        setIsTokenValid(true);
      } else {
        // nothing to do - token is false by default
      }
      setIsPending(false);
    }

    validateToken();
  }, []);

  function backToLoginHandler() {
    navigation("/");
  }

  return (
    <div className="text-center">
      {isPending && (
        <div className="flex items-center flex-col">
          <div className="text-gray-700">
            Validating your password reset request
          </div>
          <div className="mt-4">
            <Spinner sizeClassNames="h-10 w-10"></Spinner>
          </div>
        </div>
      )}
      {!isPending && isTokenValid && (
        <div className="flex items-center flex-col text-green-700">
          <div className="mb-2">
            <ShieldCheckIcon className="h-10 w-10"></ShieldCheckIcon>
          </div>
          <div>
            Your password reset request has been validated. Please check your
            email.
          </div>
        </div>
      )}
      {!isPending && !isTokenValid && (
        <div className="flex items-center flex-col text-amber-700">
          <div className="mb-2">
            <ShieldExclamationIcon className="h-10 w-10"></ShieldExclamationIcon>
          </div>
          <div>
            Your password reset request may have already been used or is no
            longer valid.
          </div>
          <div className="mt-3 text-sm">
            Please check your email. If you haven't received a new password,
            please try the reset process again.
          </div>
        </div>
      )}
      <div className="mt-6">
        {!isPending && (
          <ActionText
            onClick={backToLoginHandler}
            text="Back to Login"
          ></ActionText>
        )}
      </div>
    </div>
  );
};

export default PasswordForgotTokenPage;
