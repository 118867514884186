import { Link } from "react-router-dom";

const BackToIndexButton = () => {
    return (
        <Link to="/" className="border-2 hover:bg-gray-200 text-sm focus:outline-none focus:ring-2 focus:ring-focus rounded px-2 py-1 font-semibold leading-6 text-gray-700 shadow-sm">
            Back to Login
        </Link>
    );
};

export default BackToIndexButton;
