import { useRouteError } from "react-router-dom";
import { useEffect } from "react";
import BackToIndexButton from "../components/BackToIndexButton";

const ErrorPage = () => {
    const identifier = crypto.randomUUID();
    const error = useRouteError();
    useEffect(() => {
        if (error instanceof Error){
            const errorObj = {
                message: error.message,
                name: error.name,
                stack: error.stack,
            };

            fetch("api/unauth/error", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    identifier,
                    error: errorObj,
                }),
            });
        }

    }, []);

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px] min-w-[200px]">
                    <div className="bg-white pb-12 pt-6 shadow sm:rounded-md sm:px-12 text-center">
                        <div className="text-center text-gray-900 mb-12">
                            <h1 className="text-2xl font-semibold mb-4">
                                Error!
                            </h1>
                            <p>
                                Looks like something wrong happened. We're sorry
                                for the inconvenience.
                            </p>
                            <p className="mt-2">
                                Our team has been notified of the issue. Thank
                                you for your understanding.
                            </p>
                            <p className="text-sm mt-6 text-gray-400">
                                Reference: {identifier}
                            </p>
                        </div>
                        <BackToIndexButton />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ErrorPage;
