import Input from "../components/Form/Input";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import useEmailFromLocationState from "../hooks/useEmailFromLocationState";
import ActionText from "../components/Form/ActionText";
import SubmitButton from "../components/Form/SubmitButton";
import { useState } from "react";
import ExternalLoginButtons from "../components/Form/ExternalLoginButtons";
import usePostFormData from "../hooks/usePostFormData";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import FormState from "../models/FormState";
import maskEmail from "../helpers/MaskEmail";
import isRelativeUrl from "../helpers/IsRelativeUrl";

const LoginPage = () => {
    const [formState, setFormState] = useState(FormState.Normal);
    const [incorrectTries, setIncorrectTries] = useState(0);
    const [feedback, setFeedback] = useState("");
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const navigation = useNavigate();
    const email = useEmailFromLocationState();
    const postFormData = usePostFormData();

    function forgotPasswordHandler() {
        navigation("/forgot-password", {
            state: { email: emailRef.current?.value },
        });
    }

    async function submitHandler(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setFormState(FormState.Processing);

        const { status, data } = await postFormData.post("/", [
            {
                name: "email",
                value: emailRef.current!.value,
            },
            {
                name: "password",
                value: passwordRef.current!.value,
            },
        ]);

        if (status !== 200) {
            setIncorrectTries((prevTries) => prevTries + 1);
            setFormState(FormState.Normal);
            const errorCode = data?.error_message
                ? data.error_message
                : "Something went wrong. Please try again later.";
            setFeedback(errorCode);
        } else {
            setFormState(FormState.Completed);
            let nextUrl: string;
            let loginSuccess = false;
            if (data.next_step === "passwordExpired") {
                nextUrl = "/change-password";
            } else if (data.next_step === "passwordChangeRequired") {
                nextUrl = "/change-password";
            } else if (data.next_step === "MFA_OTP") {
                nextUrl = "/mfa-otp";
            } else if (data.next_step === "MFA_TOTP") {
                nextUrl = "/mfa-totp";
            } else if (data.next_step === "loginSuccess") {
                loginSuccess = true;
                nextUrl = isRelativeUrl(data.url) ? data.url.substr(1) : "/";
            }

            setTimeout(() => {
                if (loginSuccess){
                    window.location.href = './'+nextUrl;
                }
                else if (nextUrl === "/mfa-otp")
                    navigation(nextUrl, {
                        state: { to: maskEmail(emailRef.current!.value) },
                    });
                 else navigation(nextUrl);
            }, 500);
        }
    }
    return (
        <>
            <div className="relative mb-2">
                <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-500">
                        Login with
                    </span>
                </div>
            </div>
            <ExternalLoginButtons></ExternalLoginButtons>
            <div className="relative mb-2 mt-4">
                <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-500">
                        or use password
                    </span>
                </div>
            </div>

            <form onSubmit={submitHandler} autoComplete="off">
                <Input
                    type="email"
                    id="email"
                    required={true}
                    label="Email Address"
                    name="email"
                    autoComplete="off"
                    autoFocus={true}
                    ref={emailRef}
                    value={email}
                    disabled={
                        formState === FormState.Processing ||
                        formState === FormState.Completed
                    }
                ></Input>
                <Input
                    type="password"
                    id="password"
                    name="password"
                    required={true}
                    autoComplete="off"
                    label="Password"
                    className="mt-2"
                    ref={passwordRef}
                    disabled={
                        formState === FormState.Processing ||
                        formState === FormState.Completed
                    }
                ></Input>

                <ActionText
                    onClick={forgotPasswordHandler}
                    text="Forgot password?"
                />
                {feedback !== "" && formState === FormState.Normal && (
                    <div className="text-red-600 mb-1 flex text-sm">
                        <ExclamationTriangleIcon className="h-5 w-5 mr-1"></ExclamationTriangleIcon>
                        {feedback}
                        {incorrectTries >= 3 && (
                            <>
                                <br></br>Account is locked after 5 incorrect
                                tries.
                            </>
                        )}
                    </div>
                )}
                <SubmitButton
                    text="Login"
                    isProcessing={formState === FormState.Processing}
                    isDisabled={
                        formState === FormState.Completed ||
                        formState === FormState.Processing
                    }
                    isCompleted={formState === FormState.Completed}
                />
            </form>
        </>
    );
};

export default LoginPage;
