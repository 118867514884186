import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import usePostFormData from "../hooks/usePostFormData";
import SubmitButton from "../components/Form/SubmitButton";
import Input from "../components/Form/Input";
import SearchableSelect from "../UI/Form/SearchableSelect";
import CountryCodes from "../data/CountryCodes";
import ActionText from "../components/Form/ActionText";
import FormState from "../models/FormState";
import {
    ExclamationTriangleIcon,
    InformationCircleIcon,
} from "@heroicons/react/24/outline";
import useAccessRequestBool from "../hooks/useAccessRequestBool";

const RequestAccessPage = () => {
    const [formState, setFormState] = useState(FormState.Normal);
    const [selectedCountryCode, setSelectedCountryCode] = useState<{
        value: string;
        label: string;
    } | null>(null);
    const [feedback, setFeedback] = useState("");
    const emailRef = useRef<HTMLInputElement>(null);
    const nameRef = useRef<HTMLInputElement>(null);
    const mobileRef = useRef<HTMLInputElement>(null);
    const postFormData = usePostFormData();
    const navigation = useNavigate();
    const isRequestingEnabled = useAccessRequestBool();

    function backToLoginHandler() {
        navigation("/");
    }

    async function submitHandler(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setFormState(FormState.Processing);

        const isMobileAndCountryCodeSelected =
            mobileRef.current!.value && selectedCountryCode!.value !== "";

        const mobile = isMobileAndCountryCodeSelected
            ? mobileRef.current!.value
            : "";
        const countryCode = isMobileAndCountryCodeSelected
            ? selectedCountryCode!.value
            : "";

        const formData = [
            {
                name: "email",
                value: emailRef.current!.value,
            },
            {
                name: "name",
                value: nameRef.current!.value,
            },
            {
                name: "mobile",
                value: mobile,
            },
            {
                name: "mobile_country_code",
                value: countryCode,
            },
        ];

        const { status, data } = await postFormData.post(
            "/request-access",
            formData
        );

        if (status !== 200) {
            setFormState(FormState.Normal);
            const errorCode = data?.error_message
                ? data.error_message
                : "Something went wrong. Please try again later.";
            setFeedback(errorCode);
        } else {
            setFormState(FormState.Completed);
        }
    }

    if (!isRequestingEnabled) {
        return (
            <>
                <div className="text-center text-gray-800 mb-6">
                    Access Requesting is disabled by administrator.
                </div>
                <ActionText
                    onClick={backToLoginHandler}
                    text="Back to Login"
                ></ActionText>
            </>
        );
    }

    return (
        <form onSubmit={submitHandler}>
            <Input
                id="email"
                label="Email"
                required={true}
                type="email"
                ref={emailRef}
                autoFocus={true}
                autoComplete="off"
                disabled={
                    formState === FormState.Processing ||
                    formState === FormState.Completed
                }
            />
            <Input
                id="name"
                label="Name"
                required={true}
                type="text"
                ref={nameRef}
                autoComplete="off"
                disabled={
                    formState === FormState.Processing ||
                    formState === FormState.Completed
                }
            />

            <div className="relative mb-2 mt-7">
                <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                >
                    <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-500">
                        Optional:
                    </span>
                </div>
            </div>
            <div className="grid grid-cols-12 gap-4">
                <div className="col-span-5 block text-sm font-medium leading-6 text-gray-500">
                    <SearchableSelect
                        options={CountryCodes.map(
                            (code: { id: string; text: string }) => ({
                                label: code.text,
                                value: code.id,
                            })
                        )}
                        isSearchable={true}
                        label="Country Code"
                        selectFirstOption={true}
                        onSelectedOptionsChange={(
                            selectedOptions: Array<{
                                value: string;
                                label: string;
                            }>
                        ) => setSelectedCountryCode(selectedOptions[0])}
                        isDisabled={
                            formState === FormState.Processing ||
                            formState === FormState.Completed
                        }
                    />
                </div>
                <div className="col-span-7">
                    <Input
                        id="mobile"
                        label="Mobile"
                        required={false}
                        type="tel"
                        ref={mobileRef}
                        autoComplete="off"
                        labelClassName="text-gray-500"
                        disabled={
                            formState === FormState.Processing ||
                            formState === FormState.Completed
                        }
                    />
                </div>
            </div>

            <ActionText
                onClick={backToLoginHandler}
                text="Back to Login"
            ></ActionText>

            {feedback !== "" && formState === FormState.Normal && (
                <div className="text-red-600 mb-1 flex text-sm">
                    <ExclamationTriangleIcon className="h-5 w-5 mr-1"></ExclamationTriangleIcon>
                    {feedback}
                </div>
            )}

            {formState === FormState.Completed && (
                <div className="text-green-600 mb-1 flex text-sm">
                    <InformationCircleIcon className="h-5 w-5 mr-1"></InformationCircleIcon>
                    Request received. Please check your email for details.
                </div>
            )}

            <SubmitButton
                text="Request Access"
                isProcessing={formState === FormState.Processing}
                isDisabled={
                    formState === FormState.Completed ||
                    formState === FormState.Processing
                }
                isCompleted={formState === FormState.Completed}
            />
        </form>
    );
};

export default RequestAccessPage;
