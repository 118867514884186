const usePostFormData = () => {
    const csrfToken = document.querySelector('meta[id="csrf-token"]')!.getAttribute('content');
    
    const post = async (url: string, payload: { name: string, value: string }[]) => {
        const formData = new FormData();
        payload.forEach((item) => {
            formData.append(item.name, item.value);
        });
        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    'X-CSRF-Token': csrfToken!,
                }
            });
            const data = await response.json();
            
            return {
                status: response.status,
                data
            };
        } catch (error) {
            return {
                status: 500, 
                data: { error_message: "There was an error. Please try again." }
            };
        }
    };
    return { post };
}

export default usePostFormData;