 const countryCodes = [
	{
		id: '',
		text: '-',
	},
	{
		id: '45',
		text: 'Denmark (+45)'
	},
	{
		id: '358',
		text: 'Finland (+358)'
	},
	{
		id: '33',
		text: 'France (+33)'
	},
	{
		id: '49',
		text: 'Germany (+49)'
	},
	{
		id: '48',
		text: 'Poland (+48)'
	},
	{
		id: '213',
		text: 'Algeria (+213)'
	},
	{
		id: '376',
		text: 'Andorra (+376)'
	},
	{
		id: '244',
		text: 'Angola (+244)'
	},
	{
		id: '1264',
		text: 'Anguilla (+1264)'
	},
	{
		id: '1268',
		text: 'Antigua & Barbuda (+1268)'
	},
	{
		id: '54',
		text: 'Argentina (+54)'
	},
	{
		id: '374',
		text: 'Armenia (+374)'
	},
	{
		id: '297',
		text: 'Aruba (+297)'
	},
	{
		id: '61',
		text: 'Australia (+61)'
	},
	{
		id: '43',
		text: 'Austria (+43)'
	},
	{
		id: '994',
		text: 'Azerbaijan (+994)'
	},
	{
		id: '1242',
		text: 'Bahamas (+1242)'
	},
	{
		id: '973',
		text: 'Bahrain (+973)'
	},
	{
		id: '880',
		text: 'Bangladesh (+880)'
	},
	{
		id: '1246',
		text: 'Barbados (+1246)'
	},
	{
		id: '375',
		text: 'Belarus (+375)'
	},
	{
		id: '32',
		text: 'Belgium (+32)'
	},
	{
		id: '501',
		text: 'Belize (+501)'
	},
	{
		id: '229',
		text: 'Benin (+229)'
	},
	{
		id: '1441',
		text: 'Bermuda (+1441)'
	},
	{
		id: '975',
		text: 'Bhutan (+975)'
	},
	{
		id: '591',
		text: 'Bolivia (+591)'
	},
	{
		id: '387',
		text: 'Bosnia Herzegovina (+387)'
	},
	{
		id: '267',
		text: 'Botswana (+267)'
	},
	{
		id: '55',
		text: 'Brazil (+55)'
	},
	{
		id: '673',
		text: 'Brunei (+673)'
	},
	{
		id: '359',
		text: 'Bulgaria (+359)'
	},
	{
		id: '226',
		text: 'Burkina Faso (+226)'
	},
	{
		id: '257',
		text: 'Burundi (+257)'
	},
	{
		id: '855',
		text: 'Cambodia (+855)'
	},
	{
		id: '237',
		text: 'Cameroon (+237)'
	},
	{
		id: '1',
		text: 'Canada (+1)'
	},
	{
		id: '238',
		text: 'Cape Verde Islands (+238)'
	},
	{
		id: '1345',
		text: 'Cayman Islands (+1345)'
	},
	{
		id: '236',
		text: 'Central African Republic (+236)'
	},
	{
		id: '56',
		text: 'Chile (+56)'
	},
	{
		id: '86',
		text: 'China (+86)'
	},
	{
		id: '57',
		text: 'Colombia (+57)'
	},
	{
		id: '269',
		text: 'Comoros (+269)'
	},
	{
		id: '242',
		text: 'Congo (+242)'
	},
	{
		id: '682',
		text: 'Cook Islands (+682)'
	},
	{
		id: '506',
		text: 'Costa Rica (+506)'
	},
	{
		id: '385',
		text: 'Croatia (+385)'
	},
	{
		id: '90',
		text: 'Cyprus - North (+90)'
	},
	{
		id: '357',
		text: 'Cyprus - South (+357)'
	},
	{
		id: '420',
		text: 'Czech Republic (+420)'
	},
	{
		id: '253',
		text: 'Djibouti (+253)'
	},
	{
		id: '1809',
		text: 'Dominica (+1809)'
	},
	{
		id: '1809',
		text: 'Dominican Republic (+1809)'
	},
	{
		id: '593',
		text: 'Ecuador (+593)'
	},
	{
		id: '20',
		text: 'Egypt (+20)'
	},
	{
		id: '503',
		text: 'El Salvador (+503)'
	},
	{
		id: '240',
		text: 'Equatorial Guinea (+240)'
	},
	{
		id: '291',
		text: 'Eritrea (+291)'
	},
	{
		id: '372',
		text: 'Estonia (+372)'
	},
	{
		id: '251',
		text: 'Ethiopia (+251)'
	},
	{
		id: '500',
		text: 'Falkland Islands (+500)'
	},
	{
		id: '298',
		text: 'Faroe Islands (+298)'
	},
	{
		id: '679',
		text: 'Fiji (+679)'
	},
	{
		id: '594',
		text: 'French Guiana (+594)'
	},
	{
		id: '689',
		text: 'French Polynesia (+689)'
	},
	{
		id: '241',
		text: 'Gabon (+241)'
	},
	{
		id: '220',
		text: 'Gambia (+220)'
	},
	{
		id: '7880',
		text: 'Georgia (+7880)'
	},
	{
		id: '233',
		text: 'Ghana (+233)'
	},
	{
		id: '350',
		text: 'Gibraltar (+350)'
	},
	{
		id: '30',
		text: 'Greece (+30)'
	},
	{
		id: '299',
		text: 'Greenland (+299)'
	},
	{
		id: '1473',
		text: 'Grenada (+1473)'
	},
	{
		id: '590',
		text: 'Guadeloupe (+590)'
	},
	{
		id: '671',
		text: 'Guam (+671)'
	},
	{
		id: '502',
		text: 'Guatemala (+502)'
	},
	{
		id: '224',
		text: 'Guinea (+224)'
	},
	{
		id: '245',
		text: 'Guinea - Bissau (+245)'
	},
	{
		id: '592',
		text: 'Guyana (+592)'
	},
	{
		id: '509',
		text: 'Haiti (+509)'
	},
	{
		id: '504',
		text: 'Honduras (+504)'
	},
	{
		id: '852',
		text: 'Hong Kong (+852)'
	},
	{
		id: '36',
		text: 'Hungary (+36)'
	},
	{
		id: '354',
		text: 'Iceland (+354)'
	},
	{
		id: '91',
		text: 'India (+91)'
	},
	{
		id: '62',
		text: 'Indonesia (+62)'
	},
	{
		id: '964',
		text: 'Iraq (+964)'
	},
	{
		id: '353',
		text: 'Ireland (+353)'
	},
	{
		id: '972',
		text: 'Israel (+972)'
	},
	{
		id: '39',
		text: 'Italy (+39)'
	},
	{
		id: '1876',
		text: 'Jamaica (+1876)'
	},
	{
		id: '81',
		text: 'Japan (+81)'
	},
	{
		id: '962',
		text: 'Jordan (+962)'
	},
	{
		id: '7',
		text: 'Kazakhstan (+7)'
	},
	{
		id: '254',
		text: 'Kenya (+254)'
	},
	{
		id: '686',
		text: 'Kiribati (+686)'
	},
	{
		id: '82',
		text: 'Korea - South (+82)'
	},
	{
		id: '965',
		text: 'Kuwait (+965)'
	},
	{
		id: '996',
		text: 'Kyrgyzstan (+996)'
	},
	{
		id: '856',
		text: 'Laos (+856)'
	},
	{
		id: '371',
		text: 'Latvia (+371)'
	},
	{
		id: '961',
		text: 'Lebanon (+961)'
	},
	{
		id: '266',
		text: 'Lesotho (+266)'
	},
	{
		id: '231',
		text: 'Liberia (+231)'
	},
	{
		id: '218',
		text: 'Libya (+218)'
	},
	{
		id: '417',
		text: 'Liechtenstein (+417)'
	},
	{
		id: '370',
		text: 'Lithuania (+370)'
	},
	{
		id: '352',
		text: 'Luxembourg (+352)'
	},
	{
		id: '853',
		text: 'Macao (+853)'
	},
	{
		id: '389',
		text: 'Macedonia (+389)'
	},
	{
		id: '261',
		text: 'Madagascar (+261)'
	},
	{
		id: '265',
		text: 'Malawi (+265)'
	},
	{
		id: '60',
		text: 'Malaysia (+60)'
	},
	{
		id: '960',
		text: 'Maldives (+960)'
	},
	{
		id: '223',
		text: 'Mali (+223)'
	},
	{
		id: '356',
		text: 'Malta (+356)'
	},
	{
		id: '692',
		text: 'Marshall Islands (+692)'
	},
	{
		id: '596',
		text: 'Martinique (+596)'
	},
	{
		id: '222',
		text: 'Mauritania (+222)'
	},
	{
		id: '269',
		text: 'Mayotte (+269)'
	},
	{
		id: '52',
		text: 'Mexico (+52)'
	},
	{
		id: '691',
		text: 'Micronesia (+691)'
	},
	{
		id: '373',
		text: 'Moldova (+373)'
	},
	{
		id: '377',
		text: 'Monaco (+377)'
	},
	{
		id: '976',
		text: 'Mongolia (+976)'
	},
	{
		id: '1664',
		text: 'Montserrat (+1664)'
	},
	{
		id: '212',
		text: 'Morocco (+212)'
	},
	{
		id: '258',
		text: 'Mozambique (+258)'
	},
	{
		id: '95',
		text: 'Myanmar (+95)'
	},
	{
		id: '264',
		text: 'Namibia (+264)'
	},
	{
		id: '674',
		text: 'Nauru (+674)'
	},
	{
		id: '977',
		text: 'Nepal (+977)'
	},
	{
		id: '31',
		text: 'Netherlands (+31)'
	},
	{
		id: '687',
		text: 'New Caledonia (+687)'
	},
	{
		id: '64',
		text: 'New Zealand (+64)'
	},
	{
		id: '505',
		text: 'Nicaragua (+505)'
	},
	{
		id: '227',
		text: 'Niger (+227)'
	},
	{
		id: '234',
		text: 'Nigeria (+234)'
	},
	{
		id: '683',
		text: 'Niue (+683)'
	},
	{
		id: '672',
		text: 'Norfolk Islands (+672)'
	},
	{
		id: '670',
		text: 'Northern Marianas (+670)'
	},
	{
		id: '47',
		text: 'Norway (+47)'
	},
	{
		id: '968',
		text: 'Oman (+968)'
	},
	{
		id: '92',
		text: 'Pakistan (+92)'
	},
	{
		id: '680',
		text: 'Palau (+680)'
	},
	{
		id: '507',
		text: 'Panama (+507)'
	},
	{
		id: '675',
		text: 'Papua New Guinea (+675)'
	},
	{
		id: '595',
		text: 'Paraguay (+595)'
	},
	{
		id: '51',
		text: 'Peru (+51)'
	},
	{
		id: '63',
		text: 'Philippines (+63)'
	},
	{
		id: '351',
		text: 'Portugal (+351)'
	},
	{
		id: '1787',
		text: 'Puerto Rico (+1787)'
	},
	{
		id: '974',
		text: 'Qatar (+974)'
	},
	{
		id: '262',
		text: 'Reunion (+262)'
	},
	{
		id: '40',
		text: 'Romania (+40)'
	},
	{
		id: '7',
		text: 'Russia (+7)'
	},
	{
		id: '250',
		text: 'Rwanda (+250)'
	},
	{
		id: '378',
		text: 'San Marino (+378)'
	},
	{
		id: '239',
		text: 'Sao Tome & Principe (+239)'
	},
	{
		id: '966',
		text: 'Saudi Arabia (+966)'
	},
	{
		id: '221',
		text: 'Senegal (+221)'
	},
	{
		id: '381',
		text: 'Serbia (+381)'
	},
	{
		id: '248',
		text: 'Seychelles (+248)'
	},
	{
		id: '232',
		text: 'Sierra Leone (+232)'
	},
	{
		id: '65',
		text: 'Singapore (+65)'
	},
	{
		id: '421',
		text: 'Slovak Republic (+421)'
	},
	{
		id: '386',
		text: 'Slovenia (+386)'
	},
	{
		id: '677',
		text: 'Solomon Islands (+677)'
	},
	{
		id: '252',
		text: 'Somalia (+252)'
	},
	{
		id: '27',
		text: 'South Africa (+27)'
	},
	{
		id: '34',
		text: 'Spain (+34)'
	},
	{
		id: '94',
		text: 'Sri Lanka (+94)'
	},
	{
		id: '290',
		text: 'St. Helena (+290)'
	},
	{
		id: '1869',
		text: 'St. Kitts (+1869)'
	},
	{
		id: '1758',
		text: 'St. Lucia (+1758)'
	},
	{
		id: '597',
		text: 'Suriname (+597)'
	},
	{
		id: '249',
		text: 'Sudan (+249)'
	},
	{
		id: '268',
		text: 'Swaziland (+268)'
	},
	{
		id: '46',
		text: 'Sweden (+46)'
	},
	{
		id: '41',
		text: 'Switzerland (+41)'
	},
	{
		id: '886',
		text: 'Taiwan (+886)'
	},
	{
		id: '992',
		text: 'Tajikistan (+992)'
	},
	{
		id: '66',
		text: 'Thailand (+66)'
	},
	{
		id: '228',
		text: 'Togo (+228)'
	},
	{
		id: '676',
		text: 'Tonga (+676)'
	},
	{
		id: '1868',
		text: 'Trinidad & Tobago (+1868)'
	},
	{
		id: '216',
		text: 'Tunisia (+216)'
	},
	{
		id: '90',
		text: 'Turkey (+90)'
	},
	{
		id: '993',
		text: 'Turkmenistan (+993)'
	},
	{
		id: '1649',
		text: 'Turks & Caicos Islands (+1649)'
	},
	{
		id: '688',
		text: 'Tuvalu (+688)'
	},
	{
		id: '256',
		text: 'Uganda (+256)'
	},
	{
		id: '380',
		text: 'Ukraine (+380)'
	},
	{
		id: '44',
		text: 'United Kingdom (+44)'
	},
	{
		id: '971',
		text: 'United Arab Emirates (+971)'
	},
	{
		id: '598',
		text: 'Uruguay (+598)'
	},
	{
		id: '1',
		text: 'USA (+1)'
	},
	{
		id: '998',
		text: 'Uzbekistan (+998)'
	},
	{
		id: '678',
		text: 'Vanuatu (+678)'
	},
	{
		id: '379',
		text: 'Vatican City (+379)'
	},
	{
		id: '58',
		text: 'Venezuela (+58)'
	},
	{
		id: '84',
		text: 'Vietnam (+84)'
	},
	{
		id: '1',
		text: 'Virgin Islands - British (+1)'
	},
	{
		id: '1',
		text: 'Virgin Islands - US (+1)'
	},
	{
		id: '681',
		text: 'Wallis & Futuna (+681)'
	},
	{
		id: '969',
		text: 'Yemen (North)(+969)'
	},
	{
		id: '967',
		text: 'Yemen (South)(+967)'
	},
	{
		id: '260',
		text: 'Zambia (+260)'
	},
	{
		id: '263',
		text: 'Zimbabwe (+263)'
	}
]

export default countryCodes;