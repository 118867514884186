import AccessRequest from "../components/AccessRequestText/AccessRequest";
import Brand from "../components/Brand/Brand";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useLocation, useOutlet } from "react-router-dom";
import {useRef} from 'react';

function Root() {
    const location = useLocation();
    const currentOutlet = useOutlet();
    const refNode = useRef(null);

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center xs:py-12 sm:px-6 lg:px-8">
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px] min-w-[200px]">
                    <div className="bg-white pb-12 pt-6 shadow rounded-md px-6 xs:px-12 xs:mx-12 mx-4 sm:mx-0">
                        <Brand />
                        <SwitchTransition>
                            <CSSTransition
                                key={location.pathname}
                                timeout={150}
                                classNames="page"
                                unmountOnExit
                                nodeRef={refNode}
                            >
                              <div ref={refNode}>
                                {currentOutlet}
                              </div>
                            </CSSTransition>
                        </SwitchTransition>
                    </div>
                    <AccessRequest />
                </div>
            </div>
        </>
    );
}

export default Root;
