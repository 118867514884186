import {
    ChevronUpIcon,
    CheckIcon,
    ChevronDownIcon,
  } from "@heroicons/react/20/solid";
  import { useState, useEffect, useRef } from "react";
  import SearchableSelectProps from "../../models/SearchableSelectProps";
  
  const SearchableSelect = ({
    options,
    multiple,
    isSearchable,
    autoFocus,
    selectFirstOption,
    onSelectedOptionsChange,
    label,
    isDisabled
  }: SearchableSelectProps & { label?: string }) => {
    const [showOptions, setShowOptions] = useState(autoFocus);
    const [selectedOptions, setSelectedOptions] = useState(
      selectFirstOption ? [options[0]] : []
    );
    const [searchValue, setSearchValue] = useState("");
    const currentSelectRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLInputElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);
  
    const optionsValues = selectedOptions.map((o) => o.value);
    const selectedText = selectedOptions.map((o) => o.label).join(", ");
    const icon = showOptions ? (
      <ChevronUpIcon className="h-5 w-5" />
    ) : (
      <ChevronDownIcon className="h-5 w-5" />
    );
  
    useEffect(() => {
      onSelectedOptionsChange(selectedOptions);
    }, [selectedOptions]);
  
    // add event listener to close the dropdown when clicked outside
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        const isClickOutside = !currentSelectRef.current?.contains(
          event.target as Node
        );
  
        if (isClickOutside) {
          setShowOptions(false);
        }
      }
  
      window.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    useEffect(() => {
      setSearchValue("");
      if (showOptions && searchRef.current) {
        searchRef.current.focus();
      }
    }, [showOptions]);
  
    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
    };
  
    // when search input active - pick options on Enter, close on Escape
    let shakeTimeout: NodeJS.Timeout | null;
    function handleKeyDownInSearch(e: React.KeyboardEvent) {
      if (e.key === "Enter") {
        e.preventDefault();
        if (filteredOptions.length === 1) {
          addOrRemoveOption(filteredOptions[0]);
        } else {
          optionsRef.current?.classList.add("animate-shake");
          optionsRef.current?.classList.add("text-red-500");
          if (shakeTimeout) clearTimeout(shakeTimeout);
          shakeTimeout = setTimeout(() => {
            optionsRef.current?.classList.remove("animate-shake");
            optionsRef.current?.classList.remove("text-red-500");
          }, 1000);
        }
      } else if (e.key === "Escape") {
        setShowOptions(false);
      }
    }
  
    const filteredOptions = searchValue
      ? options.filter((o) =>
          o.label.toLowerCase().includes(searchValue.toLowerCase())
        )
      : options;
  
    function toggleOptionsHandler() {
      if (isDisabled) return;
      setShowOptions((prevState) => !prevState);
    }
  
    function addOrRemoveOption(option: { value: string; label: string }) {
      if (!multiple) {
        setSelectedOptions([option]);
        setShowOptions(false);
        return;
      }
  
      if (isSelected(option)) {
        setSelectedOptions((prevState) =>
          prevState.filter((o) => o.value !== option.value)
        );
        return;
      } else setSelectedOptions((prevState) => [...prevState, option]);
    }
  
    // add or remove options
    function onOptionChoose(
      e: React.MouseEvent,
      option: { value: string; label: string }
    ) {
      addOrRemoveOption(option);
    }
  
    function isSelected(option: { value: string; label: string }) {
      return optionsValues.includes(option.value);
    }
  
    return (
      <>
        {label && <label className="block mb-1">{label}</label>} 
        <div
          className={`dropdown-container relative text-left border-none rounded  ${isDisabled ? "bg-gray-300" : "bg-white"} py-1 text-gray-900  ring-inset ring-1 ring-gray-300 focus:ring-2 focus:ring-inset sm:text-sm`}
          ref={currentSelectRef}
        >
          <div
            onClick={toggleOptionsHandler}
            className="dropdown-input px-2 py-1 flex items-center select-none justify-between cursor-pointer whitespace-nowrap overflow-hidden"
            title={selectedText}
          >
            <div>{selectedText}</div>
            <div className="dropdown-tools">
              <div className="dropdown-tool">{icon}</div>
            </div>
          </div>
          {showOptions && (
            <div className="z-10 dropdown-menu absolute border rounded overflow-auto max-h-40 bg-white translate-y-1 cursor-pointer">
              {isSearchable && (
                <div className="search-box">
                  <input
                    onChange={onSearch}
                    value={searchValue}
                    ref={searchRef}
                    className="w-full bg-blue-300 p-1 text-sm"
                    onKeyDown={handleKeyDownInSearch}
                  />
                </div>
              )}
              <div className="dropdown-options" ref={optionsRef}>
                {filteredOptions.length === 0 && (
                  <p className="text-sm p-1 text-gray-700">No options found.</p>
                )}
                {filteredOptions.map((option) => (
                  <div
                    key={option.label}
                    className={`flex items-center px-2 py-1 hover:bg-gray-100 ${
                      isSelected(option) ? "bg-green-200" : ""
                    }`}
                    onClick={(e) => onOptionChoose(e, option)}
                    title={option.label}
                  >
                    {isSelected(option) && <CheckIcon className="h-3 w-3 mr-1" />}{" "}
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };
  
  export default SearchableSelect;
  