import useAccessRequestBool from "../../hooks/useAccessRequestBool";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const AccessRequest = () => {
    const isEnabled = useAccessRequestBool();
    const location = useLocation();

    const showAccessRequest =
        (location.pathname === "/" ||
            location.pathname === "/forgot-password");

    if (!showAccessRequest) {
      return null;
    }

    if (!isEnabled) {
        return (
            <p className="mt-5 xs:mt-10 mx-20 xs:mx-0 text-center text-sm text-gray-400">
                To request an account, please contact your project team.
            </p>
        );
    }

    return (
        <p className="mt-10 text-center text-sm text-gray-400">
            Not a member?{" "}
            <Link
                to="/request-access"
                className="font-semibold leading-6 text-gray-400 hover:text-gray-200"
            >
                Request Access
            </Link>
        </p>
    );
};

export default AccessRequest;
