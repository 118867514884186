import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Root from "./pages/Root";
import LoginPage from "./pages/Login";
import PasswordForgotPage from "./pages/PasswordForgot";
import ChangePasswordPage from "./pages/ChangePassword";
import MFAPage from "./pages/MFA";
import RequestAccessPage from "./pages/RequestAccess";
import PasswordForgotTokenPage from "./pages/PasswordForgotToken";
import NotFoundPage from "./pages/NotFound";
import ErrorPage from "./pages/Error";
import MessagePage from "./pages/Message";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root></Root>,
    errorElement: <ErrorPage></ErrorPage>,
    children: [
      {
        index: true,
        element: <LoginPage></LoginPage>
      },
      {
        path: "forgot-password",
        element: <PasswordForgotPage></PasswordForgotPage>,
      },
      {
        path: "forgot-password/:token",
        element: <PasswordForgotTokenPage></PasswordForgotTokenPage>,
      },
      {
        path: "mfa-otp",
        element: <MFAPage message="Enter the code sent to:" pinLength={8}/>,
      }, {
        path: "mfa-totp",
        element: <MFAPage message="Enter the code from Authenticator" pinLength={6} />,
      }, 
      {
        path: "request-access",
        element: <RequestAccessPage></RequestAccessPage>,
      },
      {
        path: "change-password",
        element: <ChangePasswordPage message="Set up a new password:"></ChangePasswordPage>,
      },
      {
        path: "password-expired",
        element: <ChangePasswordPage message="Your password has expired. Set up a new password:"></ChangePasswordPage>,
      },
      {
        path: 'account-disabled',
        element: <MessagePage message="Your account is disabled. Please reset your password to unlock it."></MessagePage>
      },
      {
        path: 'request-pending',
        element: <MessagePage message="Access has been requested and is pending. Contact the project team for urgent processing"></MessagePage>
      },
      {
        path: "*",
        element: <NotFoundPage></NotFoundPage>,
      }
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
