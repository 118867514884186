// hook returns True if Access Request should be enabled, else False

const useAccessRequestBool = () => {
    // Attribute is rendered on server-side in index.html
    const isEnabled =
    document.getElementById("root")!.getAttribute("data-requests-enabled") ===
    "True";

    return isEnabled;
}

export default useAccessRequestBool;