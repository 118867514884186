import usePostFormData from "../../hooks/usePostFormData";
import { FunctionComponent, useState } from "react";

interface ExternalLoginButtonProps {
    providerName: string;
    Logo: FunctionComponent;
    classes: string;
    bgClass?: string; // Optional background class
}

const ExternalLoginButton: FunctionComponent<ExternalLoginButtonProps>  = ({providerName, Logo, classes, bgClass}) => {
    const [clicked, setClicked] = useState(false);
    const postForm = usePostFormData();
    async function onClickHandler() {
        setClicked(true);
        const { data } = await postForm.post("/sso/login", [
            {
                name: "provider",
                value: providerName,
            }
        ]);
        window.location.assign(data.redirect_url);
    }

    const backgroundClass = bgClass || 'bg-white';

    return (
        <button
            onClick={onClickHandler}
            type="button"
            className={`${backgroundClass} ${classes} border h-12 xs:h-10 ${classes} flex w-full justify-center rounded px-3 pb-1.5 pt-2 shadow-sm mb-2 ${clicked ? "animate-pulse" : ""}`}
            disabled={clicked}
        >
            <Logo></Logo>
        </button>
    );
};

export default ExternalLoginButton;
