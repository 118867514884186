import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import Input from "../components/Form/Input";
import useEmailFromLocationState from "../hooks/useEmailFromLocationState";
import ActionText from "../components/Form/ActionText";
import SubmitButton from "../components/Form/SubmitButton";
import FormState from "../models/FormState";
import usePostFormData from "../hooks/usePostFormData";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

const PasswordForgotPage = () => {
  const navigation = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);
  const email = useEmailFromLocationState();
  const [formState, setFormState] = useState(FormState.Normal);
  const [feedback, setFeedback] = useState("");
  const postFormData = usePostFormData();

  function backToLoginHandler() {
    navigation("/", {
      state: { email: emailRef.current?.value },
    });
  }

  async function submitHandler(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setFormState(FormState.Processing);

    const formData = [
      {
        name: "email",
        value: emailRef.current!.value,
      },
    ];

    const { status, data } = await postFormData.post(
      "/forgot-password",
      formData
    );

    if (status !== 200) {
      setFormState(FormState.Normal);
      const errorCode = data?.error_message
        ? data.error_message
        : "Something went wrong. Please try again later.";
      setFeedback(errorCode);
    } else {
      setFormState(FormState.Completed);
    }
  }

  return (
    <form onSubmit={submitHandler}>
      <Input
        type="email"
        id="email"
        required={true}
        label="Email Address"
        name="email"
        autoComplete="off"
        ref={emailRef}
        value={email}
        autoFocus={true}
        disabled={formState !== FormState.Normal}
      ></Input>

      <ActionText
        onClick={backToLoginHandler}
        text="Back to Login"
      ></ActionText>
      {feedback !== "" && formState === FormState.Normal && (
        <div className="text-red-600 mb-1 flex text-sm">
          <ExclamationTriangleIcon className="h-5 w-5 mr-1"></ExclamationTriangleIcon>
          {feedback}
        </div>
      )}

      {formState === FormState.Completed && (
        <div className="text-green-600 mb-1 flex text-sm">
          <div><InformationCircleIcon className="h-5 w-5 mr-1"></InformationCircleIcon></div>
          <div>If the account exists, we will send instructions to the provided email address.</div>
        </div>
      )}
      <SubmitButton
        text="Reset Password"
        isProcessing={formState === FormState.Processing}
        isDisabled={
          formState === FormState.Completed ||
          formState === FormState.Processing
        }
        isCompleted={formState === FormState.Completed}
      />
    </form>
  );
};

export default PasswordForgotPage;
