function isRelativeUrl(url: string) {
    try{
        new URL(url);
        return false;
    } catch {
        if (!url) return false;
        else if (url.indexOf("//") !== -1) {
            return false;
        }
        else if (url.indexOf(":") !== -1) {
            return false;
        }
        else if (url.indexOf(".") !== -1) {
            return false;
        }
        else if (url.indexOf('http') !== -1) {
            return false;
        }

        return true;
    }
}

export default isRelativeUrl;