import BackToIndexButton from "../components/BackToIndexButton";
import { FunctionComponent } from "react";

const MessagePage: FunctionComponent<{ message: string }> = ({ message }) => {
    return (
        <div className="text-center text-gray-900">
            <p className="mb-12 mt-4">{message}</p>
            <BackToIndexButton />
        </div>
    );
};
export default MessagePage;
