// component for secondary actions within form such as "Forgot Password?" or "Back to Login"
import { FunctionComponent } from "react";

type ActionTextProps = {
  onClick: () => void;
  text: string;
};

const ActionText: FunctionComponent<ActionTextProps> = ({ onClick, text }) => {
  return (
    <div className="text-sm text-right leading-6 mt-1 mb-2">
      <button
        type="button"
        className="font-semibold text-gray-600 hover:text-gray-400"
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  );
};

export default ActionText;
