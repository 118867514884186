import React, { useState, useRef, useEffect } from "react";
import usePostFormData from "../hooks/usePostFormData";
import SubmitButton from "../components/Form/SubmitButton";
import {
    ExclamationTriangleIcon,
    LockClosedIcon,
} from "@heroicons/react/24/outline";
import FormState from "../models/FormState";
import { useLocation } from "react-router-dom";
import PinField from "react-pin-field";
import "./../index.css";
import isRelativeUrl from "../helpers/IsRelativeUrl";

type MFAProps = {
    message: string;
    pinLength: number;
};

const MFAPage = ({ message, pinLength }: MFAProps) => {
    const [formState, setFormState] = useState(FormState.Normal);
    const [feedback, setFeedback] = useState("");
    const postFormData = usePostFormData();
    const pinFieldRef = useRef<HTMLInputElement[]>([]);
    const [pin, setPin] = useState("");
    const location = useLocation();
    const sentTo = location.state?.to;

    // auto focus on first input field
    useEffect(() => {
        if (pinFieldRef.current && pinFieldRef.current[0]) {
            pinFieldRef.current[0].focus();
        }
    }, []);

    async function submitHandler(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!pin) return;
        setFormState(FormState.Processing);
        const formData = [
            {
                name: "code",
                value: pin,
            },
        ];
        const { status, data } = await postFormData.post(
            "/verify-mfa",
            formData
        );
        if (status !== 200) {
            setFormState(FormState.Normal);
            const errorCode = data?.error_message
                ? data.error_message
                : "Something went wrong. Please try again later.";
            setFeedback(errorCode);
        } else {
            setFormState(FormState.Completed);
            setTimeout(() => {
                window.location.href = isRelativeUrl(data.url)
                    ? "./" + data.url.substring(1)
                    : "/";
            }, 500);
        }
    }

    return (
        <form onSubmit={submitHandler}>
            <div className="flex flex-col items-center mt-7 text-center">
                <div className="flex items-center mx-6 mb-8 text-gray-700">
                    <LockClosedIcon className="h-4 w-4 mr-1"></LockClosedIcon>
                    <p className="font-bold">Confirm your login</p>
                </div>
                <span className="px-6 pb-2 text-gray-600">{message}</span>
                {sentTo && <span className="px-6 text-gray-600">{sentTo}</span>}
            </div>
            <div className="flex space-x-2 justify-center mt-8 mb-8">
                <PinField
                    ref={pinFieldRef}
                    length={pinLength}
                    validate={/^[0-9]$/}
                    className="w-10 h-10 rounded-md border-2 border-gray-400 text-center text-lg text-core-purple-2 font-bold focus:border-core-purple-1 focus:border-2"
                    style={
                        {
                            "--tw-ring-color": "core-purple-1",
                            "--tw-ring-shadow": "core-purple-2",
                        } as React.CSSProperties
                    }
                    onChange={(value) => setPin(value)}
                    disabled={
                        formState === FormState.Processing ||
                        formState === FormState.Completed
                    }
                />
            </div>

            {feedback !== "" && formState === FormState.Normal && (
                <div className="text-red-600 mb-1 flex text-sm">
                    <ExclamationTriangleIcon className="h-5 w-5 mr-1"></ExclamationTriangleIcon>
                    {feedback}
                </div>
            )}
            <div>
                <SubmitButton
                    text="Login"
                    isProcessing={formState === FormState.Processing}
                    isDisabled={formState !== FormState.Normal || !pin}
                    isCompleted={formState === FormState.Completed}
                />
            </div>
        </form>
    );
};

export default MFAPage;
