import BackToIndexButton from "../components/BackToIndexButton";

const NotFoundPage = () => {
    return (
        <div className="text-center text-gray-900">
            <h1 className="text-2xl font-semibold">404</h1>
            <p className="mb-12">Page not found</p>
            <BackToIndexButton />
        </div>
    );
};
export default NotFoundPage;
