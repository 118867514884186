function maskEmail(email: string): string {
    const emailParts = email.split('@');
    if (emailParts.length !== 2) return email;

    const [localPart, domain] = emailParts;
    const maskedLocalPart = localPart.length > 3 ? (localPart.substring(0, 3) + '***' + localPart.substring(localPart.length - 2)) : localPart;
    const maskedDomain = domain.length > 3 ? (domain.substring(0, 3) + '***' + domain.substring(domain.length - 2)) : domain;
    
    return `${maskedLocalPart}@${maskedDomain}`;
}

export default maskEmail;